








































































































import { defineComponent } from '@vue/composition-api'
import { mapActions } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import { newCarEngine } from '@/components/molecules/select-search/store/carEngine'
import { CarEngine } from '@/api/interfaces/carengine'

const IRI_EN_COURS_CREATION = 'en_cours_creation'

export default defineComponent({
  name: 'DialogCreateEditCarEngine',
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    carEngineEdit: {
      type: Object,
      required: false,
    },
    carModel: {
      type: Object,
      required: false,
    },
  },
  data () {
    return {
      isSubmiting: false,
      carEngine: newCarEngine(),
    }
  },
  computed: {
    isEditingCarEngine (): boolean {
      return (this.carEngine['@id'] !== undefined && this.carEngine['@id'] !== '')
    },
  },
  created () {
    if (this.carEngineEdit && this.carEngineEdit['@id']) {
      (this.carEngine as CarEngine) = this.carEngineEdit as CarEngine
    } else {
      this.carEngine = newCarEngine()
    }
  },
  watch: {
    carEngineEdit: {
      handler (newVal) {
        if (newVal && newVal['@id']) {
          (this.carEngine as CarEngine) = { ...this.carEngineEdit as CarEngine }
        } else {
          this.carEngine = newVal
        }
      },
    },
  },
  methods: {
    ...mapActions('carEngine', {
      save: 'save',
    }),
    async onSubmit () {
      this.isSubmiting = true
      try {
        if (this.carEngine['@id'] && this.carEngine['@id'] !== IRI_EN_COURS_CREATION) {
          // case update carEngine when edit carModel
          await this.save(this.carEngine)
          this.$emit('carEngineUpdated', this.carEngine, this.carEngineEdit)
        } else {
          if (this.carModel && this.carModel['@id']) {
            // case create carEngine when edit carModel
            this.carEngine.carModel = this.carModel['@id']
            this.carEngine = await this.save(this.carEngine)
          }
          if (this.carEngine['@id'] !== IRI_EN_COURS_CREATION) {
            // case create carEngine when create carModel
            if (!this.carEngine['@id']) {
              this.carEngine['@id'] = IRI_EN_COURS_CREATION
            }
            this.$emit('carEngineCreated', this.carEngine)
          } else {
            // case edit carEngine when create carModel
            this.$emit('carEngineUpdated', this.carEngine, this.carEngineEdit)
          }
        }
      } finally {
        this.clear()
        this.$emit('close')
      }
    },
    clear () {
      this.carEngine = newCarEngine()
      this.isSubmiting = false
      const observer = this.$refs.observerFormCarEngine as InstanceType<typeof ValidationObserver>
      observer.reset()
    },
  },
})
